import React from "react"
import { graphql } from "gatsby"

import { Sections as Page } from "../components/Sections/Sections"

export const query = graphql`
  query {
    page: sanityPageHomepage(_id: { eq: "pageHomepage" }) {
      _type
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

const Component = ({ data, ...props }) => {
  return <Page {...props} page={data.page} />
}
export default Component
