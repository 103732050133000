import React from "react"
import { withSection } from "./withSection"

import { SectionWrapper } from "./SectionStyles"
import { Badge } from "../Badge/Badge"

export const Section = withSection(({ children, space, borderTop, borderBottom, badge, settings }) => (
  <SectionWrapper space={space} borderTop={borderTop} borderBottom={borderBottom} settings={settings}>
    {badge?.icon || badge?.iconBadge ? <Badge badge={badge} /> : null}
    {children}
  </SectionWrapper>
))
