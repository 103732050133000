import React from "react"

import { withSections } from "./withSections"
import { Section } from "./Section"

export const Sections = React.memo(
  withSections(
    ({ content, ...props }): JSX.Element =>
      content
        ?.filter(section => !!section)
        ?.map(section => {
          return (
            <Section
              key={section._key}
              verticalSpace={section.verticalSpace}
              badge={section.badge}
              hide={section.hide}
              promotion={section.promotion}
              promotionVisibility={section.promotionVisibility}
              settings={section?.settings}
            >
              <section.component {...props} {...section} />
            </Section>
          )
        }) || null
  )
)
