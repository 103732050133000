import React from "react"

import { useSale } from "../../hooks/useSale"

export const withSection = Component => ({ name = "Section", children, verticalSpace, badge, hide, promotion, promotionVisibility, settings }) => {
  const { space, borderTop, borderBottom } = verticalSpace || {}

  const { getSaleVisibility } = useSale()

  let visibility
  if (settings) {
    visibility = settings.hide === "hide" ? "hide" : getSaleVisibility(settings.promotion, settings.promotionVisibility)
  } else {
    // Legacy attrs
    visibility = hide === "hide" ? "hide" : getSaleVisibility(promotion, promotionVisibility)
  }

  Component.displayName = name
  return visibility !== "hide" ? (
    <Component badge={badge} space={space} borderTop={borderTop} borderBottom={borderBottom} settings={settings}>
      {children}
    </Component>
  ) : null
}
